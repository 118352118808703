import { Link } from '@mui/material';
import { useEffect, useState } from 'react';
import translate from '../../i18n/translate';
import { useAppDispatch, useAppSelector } from '../../modules/store/hooks';
import { eventApi } from '../../modules/services/eventService';
import { eventsCountSlice } from '../../modules/store/reducers/eventsCountSlice';

import EventsDataGrid from '../UI/EventsDataGrid';

import useStyles from './useStyles';

const EventsScreen = () => {
  const classes = useStyles();
  const {
    notConfirmed,
    deleted,
    finished,
    hidden,
    all,   
    active,   
    canceled,
    inProgress,
    currentWeek,
    finishedUnpaid,
  } = useAppSelector((store)=>store.eventsCountReducer)
  const [eventsCount, setEventsCount] = useState<any[]>([])
  const [eventType, setEventType] = useState<string>('all')
  const [eventCount, setEventCount] = useState<number>(all)

  const dispatch = useAppDispatch()
    const {setCount} = eventsCountSlice.actions;
    const {
        data: eventsCountData, 
        isSuccess: isEventsCountSuccess,
    } = eventApi.useFetchEventsCountQuery('')
    
    useEffect(()=>{
        if (isEventsCountSuccess)
            dispatch(setCount({
                eventsCount: eventsCountData.eventsCount, 
                deletedCount: eventsCountData.deletedCount,
                finishedCount: eventsCountData.finishedCount,
                hiddenCount: eventsCountData.hiddenCount,
                needConfirmationCount: eventsCountData.needConfirmationCount,
                activeCount: eventsCountData.activeCount,
                canceledCount: eventsCountData.canceledCount,
                inProgressCount: eventsCountData.inProgressCount,
                currentWeekCount: eventsCountData.currentWeekCount,
                finishedUnpaidCount: eventsCountData.finishedUnpaidCount,
            }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEventsCountSuccess, eventsCountData])

  useEffect(()=>{
    setEventsCount([
      {   
        key: "needConfirmation",
        link: "/not-confirmed",
        status: translate('esnWaitingForConfirm'),
        count: notConfirmed,
      },
      {
          key: "deleted",
          link: "/deleted",
          status: translate('esnDeletedEvents'),
          count: deleted,
      },
      {
          key: "finished",
          link: "/finished",
          status: translate('esnFinishedEvents'),
          count: finished,
      },
      {
        key: "finishedUnpaid",
        link: "/",
        status: translate('esnFinishedUnpaidEvents'),
        count: finishedUnpaid,
      },
      {
          key: "hidden",
          link: "/hidden",
          status: translate('esnHiddenEvents'),
          count: hidden,
      },
      {
        key: "canceled",
        link: "/",
        status: translate('esnCanceledEvents'),
        count: canceled,
      },
      {
          key: "all",
          link: "/",
          status: translate('esnAllEvents'),
          count: all,
      },
      {
          key: "active",
          link: "/",
          status: translate('esnActiveEvents'),
          count: active,
      },
      {
          key: "inProgress",
          link: "/",
          status: translate('esnInProgressEvents'),
          count: inProgress,
      },
      {
          key: "currentWeek",
          link: "/",
          status: translate('esnCurrentWeekEvents'),
          count: currentWeek,
      },
    ])
  }, [all, deleted, finished, hidden, notConfirmed, active, canceled, inProgress, currentWeek, finishedUnpaid])


  return(
    <div className={classes.Wrapper}>
        <div className={classes.StatusesList}>
            {eventsCount.map((item, index)=>(
              <Link 
              key={index} 
              mr={2} 
              style={{
                cursor: "pointer",
                color: "#fc7f03",
                textDecorationColor: "#fc7f03",
                textDecoration: item.key === eventType ? 'underline' : "none"
              }} 
              onClick={()=>{
                setEventType(item.key)
                setEventCount(item.count)
              }
              }>
                {item.status}: <span>{item.count}</span>
              </Link>
            ))}
        </div>
        <EventsDataGrid type={eventType} count={eventCount}/>
    </div>
  )
}

export default EventsScreen;