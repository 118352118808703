import { LOCALES } from '../locales'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [LOCALES.HEBREW]: {
    //COMMON
    commonBackToTheMainPage: 'חזרה לעמוד הראשי',
    hideRows: '10 שורות ראשונות הוסתרו',

    //HEADER
    headerDevicesConnected: 'מחובר לפאנל',
    headerOptToUsers: 'הגדר משתמש OTP',
    headerOpenProfiles: 'פתח פרופילים',
    headerOpenDocuments: 'פתח מסמכים',
    headerTotalMoney: 'סך הכל כסף',
    headerTotalFee: 'עמלה כוללת',

    // SEND OTP MODAL
    otpCode: 'קוד אופטימיזציה למשתמש',
    setOtp: 'הגדר OTP',
    otpSetSuccess: 'קוד OTP לעג למשתמש למשך 60 הדקות הבאות והקוד הועתק ללוח שלך',
    otpSetFailed: 'קוד OTP נכשל',

    //DEVICES MODAL
    devicesModalTitle: 'מכשירים פעילים',
    otpModalTitle: 'הגדר משתמש OTP',
    robotCreditsModalTitle: 'שנה נקודות SMS לרובוט משתמש',
    devicesModalActionDisconnectSuccess: 'ניתוק המכשיר הצליח',
    devicesModalActionDisconnectFailure: 'שגיאת ניתוק מכשיר',

    //ADD PAYMENT MODAL
    apModalTitle: 'הוסף תשלום חדש',
    apModalDropFileHere: 'שחרר את הקובץ כאן',
    apModalDragFileHere: 'גרור את הקובץ לכאן',
    apModalUploadFile: 'העלה קובץ',
    apModalReUploadFile: 'העלה מחדש את הקובץ',

    //FILTER
    filterTitle: 'לְסַנֵן',
    filterItemOnlyPaid: 'אירועים ששולמו',
    filterItemSortByName: 'מיין לפי שם',
    filterItemAscending: 'עולה',
    filterSubmit: 'שלח',
    filterRestore: 'לשחזר',
    filterSearch: 'לחפש',

    //CUSTOM DATE RANGE PICKER
    cdrpFrom: 'מ...',
    cdrpTo: 'ל...',

    //CUSTOM DATAGRID
    customDataGridLoadMore: 'טען עוד',

    // EVENTS STATUS NAV
    esnWaitingForConfirm: 'מחכה לאישור',
    esnDeletedEvents: 'שנמחקו',
    esnFinishedEvents: 'שהסתיימו',
    esnFinishedUnpaidEvents: 'ללא תשלום',
    esnHiddenEvents: 'נסתרים',
    esnAllEvents: 'כל האירועים',
    esnActiveEvents: 'פעילים',
    esnCanceledEvents: 'שבוטלו',
    esnInProgressEvents: 'בתהליך',
    esnCurrentWeekEvents: 'השבוע הנוכחי',

    //#region PROFILES
    gridProfilesTotalClearances: 'סך סליקה כולל',
    gridProfilesTotalPurchases: 'סך כל הרכישות',
    gridProfilesAmountOfPurchasedTickets: 'כמות הכרטיסים שנרכשו',
    gridProfilesAmountOfEvents: 'כמות אירועים',
    gridProfilesProducerAndIdentityCardOrHPAndPhoneNumber: 'מידע מפיק',
    gridProfilesCreatorOfTheEvent: 'יוצר האירוע',
    gridProfilesPostalCode: 'מיקוד',
    gridProfilesCellphoneNumber: 'מספר טלפון סלולארי',
    gridProfilesLinkToProfile: 'קישור לפרופיל',
    gridProfilesDeletedUser: 'משתמש שנמחק',
    gridProfilesChangeRobotSmsCredits: 'שנה קרדיט SMS רובוט',
    gridProfilesChangeRobotSmsCreditsCount: 'קרדיט SMS רובוט:',
    gridProfilesChangeRobotSmsAccess: 'רובוט עדכונים',
    gridProfilesRestoreUser: 'שחזר משתמש',

    buttonDownloadAllInformation: 'הורד את כל המידע',
    profilesDeletedProfiles: 'פרופילים שנמחקו',
    profilesAllProfiles: 'כל הפרופילים',
    profilesTotalCreditsCountL: 'רובוט SMS סה"כ קרדיטים:',
    //#endregion

    //ALL EVENTS
    gridEventsOpenAsOwner: 'פתוח כבעלים',
    gridEventsPaymentStatus: 'סטטוס תשלום',
    gridEventsPaymentStatusIsPaidCheckBox: 'שולם',
    gridEventsOrderConfirmation: 'אישור תשלום',
    gridEventsFile: 'קובץ',
    gridEventsReceiptFile: 'קובץ קבלות',
    gridEventsInvoiceFile: 'קובץ חשבונית',
    gridEventsBankAccountInfo: 'פרטי חשבון בנק',
    gridEventsBankAccountInfoNumber: "מס' בנק",
    gridEventsBankAccountInfoAccountNumber: 'מס חשבון',
    gridEventsBankAccountFullName: 'שם מלא',
    gridEventsTotalCommisionProfit: 'סה"כ רווח עמלה',
    gridEventsTotalTicketsPurchasedInCredit: 'סך הכל כסף ללא עמלה',
    gridEventsTotalTicketsSold: 'סה"כ כרטיסים שנמכרו',
    gridEventsTotalTicketsSoldInCredit: 'כרטיסים שנמכרו באשראי',
    gridEventsCanceledMoney: 'כמות כסף שהוחזרה עקב בקשות ביטול',
    gridEventsCanceledCount: 'כמות כרטיסים עם בקשת החזר',
    gridEventsReturnedMoney: 'כסף שהוחזר',
    gridEventsReturnedCount: 'כמות כרטיסים שבוטלו',
    gridEventsProducerAndIdentityCardOrHPAndPhoneNumber: 'פרטי הפרופיל',
    gridEventsCreatorName: 'היוצר של האירוע',
    gridEventsCreatorAddress: 'מיקוד',
    gridEventsCreatorPhone: 'מספר טלפון סלולארי',
    gridEventsStartDate: 'תאריך האירוע',
    gridEventsCreateDate: 'תאריך תחילת המכירה',
    gridEventsName: 'שם האירוע',
    gridEventsStatus: 'מצב האירוע',
    gridEventsStatusDeleted: 'אירוע שנמחק',
    gridEventsStatusHidden: 'אירוע נסתר',
    gridEventsRepeatEvent: 'חזור על אירוע',
    gridEventsShowEvent: 'הצג אירוע',
    gridEventsConfirmEvent: 'אשר את האירוע',
    gridEventsDeleteEvent: 'מחק אירוע',

    //TRANSACTIONS
    invoiceTotalInvoicesBeforeVAT: 'סך החשבוניות לפני מע"מ',
    invoiceTotalVAT: 'סך המע"מ',
    invoiceTotalInvoicesIncluded: 'סך כל החשבוניות כלולות',
    invoiceThisEventHasNoSuchFiles: 'לאירוע הזה אין קבצים כאלה',
    transactionsInvoices: 'חשבוניות',
    transactionsConfirm: 'אישורים',
    transactionsCancelled: 'מבוטל',
    transactionsFetchError: 'אירעה שגיאה בעת טעינת הדף',

    //LOGIN
    loginEnterNumber: 'הזן את מספר הטלפון שלך כאן',
    loginSendCode: 'שלח קוד באמצעות SMS',
    loginEnterCode: 'הזן את קוד ההסכמה שלך כאן',
    loginVerifyCode: 'אמת קוד OTP',
    loginCodeSendSuccess: 'OTP נשלח בהצלחה',
    loginCodeInvalid: 'קוד לא תקין',
    loginError: 'שְׁגִיאָה',

    // Other
    changeUserCredits: 'עדכן את קרדיט ה-SMS של רובוט המשתמש',
    updateCreditsError: 'שְׁגִיאָה. נסה שוב בבקשה.'
  }
}
